html {
margin: 0;
padding: 0;
height: 100%;
}

body {
height: 100%;
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

font-family: RalewayRegular !important;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


#root {
display: flex;
min-height: 100%;
position: relative;
padding: 0.5rem;
}

@media (min-width: 480px) {
    #root {
    padding: 1rem;
    }
}


#background {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: url('./lowpoly.png') 50%/cover no-repeat;
}

#spinner {
display: flex;
align-items: center;
flex: 1;
justify-content: center;
}

#spinner.dark {
color: #333;
}

#spinner.light {
color: #FFF;
}
