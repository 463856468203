#app {
display: flex;
position: relative;
min-height: 100%;
padding: 1rem;
flex-direction: column;
}

@media (min-width: 480px) {
    #app {
    padding: 2rem;
    }
}


.form-control:focus {
box-shadow: none !important;
}


#login {
position: absolute;
top: 0;
right: 0;
}


.apple {
flex-direction: row-reverse !important;
justify-content: revert !important;
}


#info {
padding: 2rem 0;
text-align: center;
}

.with_token #info {
margin: 0 0 2rem 0;
border: 1px #CCC solid;
}

@media (min-width: 768px) {
    #info {
    text-align: left;
    }
}

@media (max-width: 767px) {
    #info h1 {
    padding: 1rem;
    }
}


#info_image {
width: 12rem;
/* height: 12rem; */
max-width: 100%;
object-fit: contain;
}

/* 
#exhibitions {
position: relative;
height: 16rem;
max-width: 900px;
margin: 0 auto 4rem auto;
padding: 0 1rem;
background-color: #F9F9F9;
border-top: 1rem #F9F9F9 solid;
border-bottom: 1rem #F9F9F9 solid;
overflow-x: hidden;
overflow-y: auto;
}
*/

@media (min-width: 768px) {
    #exhibitions_frame {
    padding: 0 2rem;
    }
}


#exhibitions a {
display: block;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}


#exhibitions_frame > .row + .row {
margin-top: 2rem;
padding-top: 2rem;
border-top: 1px #CCC dashed;
}

.with_token #exhibitions_frame > .row {
padding: 1rem;
border: 1px #CCC solid;
}


#exhibitions .btn-group + .btn-group {
margin: 0 0 0 0.5rem;
}

@media (max-width: 360px) {
    #exhibitions .btn-group {
    display: block !important;
    }

    #exhibitions .btn-group + .btn-group {
    margin: 0.5rem 0 0 0;
    }
}


.btn + .btn {
margin: 0 0 0 1rem;
}

.btn-group > .btn + .btn {
margin: 0 0 0 1px !important;
}

.btn-group-vertical > .btn + .btn {
margin: 1px 0 0 0 !important;
}


.btn-group-toggle {
margin: 0 0 1rem 0;
}

.btn-group-toggle > .btn {
opacity: 0.5;
}

.btn-group-toggle > .btn.active {
opacity: 1;
}


.add_image {
position: relative;
overflow: hidden;
}

.add_image > span {
display: block;
width: 100%;
height: 100%;
}

.add_image > input {
z-index: 1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
cursor: pointer;
opacity: 0;
}


.items, .items:hover {
color: #333;
text-decoration: none;
}


.items .card {
background: none !important;
border: none !important;
border-radius: 0 !important;
overflow: hidden;
}

.items .card-body {
padding-top: 0.5rem;
}


#modal_fullscreen {
z-index: 1;
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
align-items: center;
background-color: rgba(0,0,0,0.5);
justify-content: center;
}

#modal_fullscreen > svg {
color: #FFF;
font-size: 2rem;
}


.col {
padding: 1rem;
}

.card-img-top {
align-self: center;
border-radius: 0 !important;
image-orientation: from-image;
object-fit: cover;
}

.items .card-img-top {
height: 12rem;
transition: transform 500ms;
}

.items:hover .card-img-top {
transform: scale(1.1);
}


.items .card-img-top + div {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: rgba(0,0,0,0.3);
content: '';
opacity: 0;
transition: opacity 0.5s;
}

.items .card-img-top + div:hover {
opacity: 1;
}

.items .card-img-top + div > h5 {
position: relative;
top: 5rem;
color: #FFF;
text-align: center;
}


#info_modal .card-img-top {
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
}

#item_modal .card-img-top {
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
}


#item_spinner {
display: flex;
align-items: center;
justify-content: center;
}


#item {
margin: 1rem 0;
}


.item_left {
margin: 0 0 2.5rem 0;
padding-left: 1rem !important;
text-align: left;
}

.item_right {
margin: 0 0 2.5rem 0;
padding-right: 1rem !important;
text-align: right;
}

.item_left > a > div, .item_right > a > div {
position: relative;
top: -1px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.item_left > a > div {
margin: 0 0 0 1rem;
}

.item_right > a > div {
margin: 0 1rem 0 0;
}

.item_left > a > svg, .item_right > a > svg {
position: absolute;
top: 2px;
}

.item_left > a > svg {
left: 1rem;
}

.item_right > a > svg {
right: 1rem;
}


.item_facts {
margin: 0;
padding: 0;
list-style: none;
}


#item .item_facts {
margin: 1rem 2rem 0 2rem;
}

@media (min-width: 768px) {
    #item .item_facts {
    margin: 0;
    }
}


.item_facts > li > span + span {
margin: 0 0 0 0.5rem;
padding: 0 0 0 0.5rem;
border-left: 1px #333 solid;
}


.item_facts_year {
color: #333;
font-size: 13px;
font-weight: 200;
}


#image_modal {
height: 100%;
max-width: none;
margin: 0 auto;
}

#image_modal > .modal-content {
height: 100%;
background: none !important;
border: none !important;
}

#image_modal .modal-header {
height: 56px;
border: 0;
}

#image_modal .modal-body {
display: flex;
align-items: center;
justify-content: center;
height: calc(100% - 112px);
padding: 0 1rem;
}

#image_modal .modal-footer {
height: 56px;
border: 0;
}


.modal .close, .modal .close:hover {
font-family: sans-serif;
}

#image_modal .close, #image_modal .close:hover {
text-shadow: none;
}

#image_modal .spinner {
color: #333;
}



.cropping {
/* overflow: hidden !important; */
}

.cropping * {
user-select: none;
}


#crop_frame {
position: relative;
overflow: hidden;
text-align: center;
}

#crop_frame > div {
position: relative;
overflow: hidden;
}

#crop_frame > div + div {
margin: 2rem 0 0 0;
}

#crop_frame img {
max-width: 100%;
object-fit: contain;
}


.crop_crop {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
}

.crop_cut {
z-index: 1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
cursor: move;
touch-action: manipulation;
}

.crop_ne, .crop_nw, .crop_se, .crop_sw {
z-index: 1;
position: absolute;
width: 20px;
height: 20px;
background-color: #FFF;
border: 1px #333 solid;
}

.crop_ne {
top: -10px;
right: -10px;
cursor: nesw-resize;
}

.crop_nw {
top: -10px;
left: -10px;
cursor: nwse-resize;
}

.crop_se {
right: -10px;
bottom: -10px;
cursor: nwse-resize;
}

.crop_sw {
bottom: -10px;
left: -10px;
cursor: nesw-resize;
}


.navbar {
z-index: 1;
position: sticky !important;
top: 0;
margin: 0 -1rem;
padding: 0.5rem 3rem !important;
background-color: #f8f9fa;
border-bottom: 1rem #FFF solid;
}

@media (min-width: 480px) {
    .navbar {
    padding: 0.5rem 5rem !important;
    }
}

@media (max-width: 767px) {
    .navbar-nav {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    }
}

.navbar-brand {
padding: 0 !important   ;
}

.navbar-brand img {
width: 45px;
/* height: 45px; */
max-width: 100%;
object-fit: contain;
}


.dropdown-menu {
position: absolute !important;
}

@media (max-width: 767px) {
    #categories_dropdown {
    padding: 0.5rem 0;
    }
}


.navbar form {
position: relative;
}

#search_clear {
position: absolute;
right: 0.5rem;
color: #333;
}


#filter {
margin: 1rem 0;
}

#filter > button > svg {
margin: 0 0 0 0.5rem;
}



#item_nav {
margin: 2rem 0 1rem 0;
text-align: center;
}

@media (min-width: 768px) {
    #item_nav {
    display: none !important;
    }
}

.item_next, .item_prev {
margin: 0 2rem !important;
color: #777 !important;
font-size: 2rem !important;
}


#item img {
cursor: pointer;
}

.with_token #item {
padding: 1rem;
border: 1px #CCC solid;
}


.item_buttons {
margin: 1rem auto 0 auto;
text-align: center;
}


.item_arrows {
display: flex;
align-items: center;
font-size: 2rem;
justify-content: center;
}

.item_arrows > a {
color: #333;
outline: none;
}


.view_switch > button, .view_switch > .active {
background-color: #16728f !important;
border-color: #16728f !important;
opacity: 0.8;
}

.view_switch > .active, .view_switch > button:hover {
opacity: 1;
}


#no_results_found {
margin: 2.5rem 0 0 0;
text-align: center;
}


.carousel {
height: 100%;
padding: 0 3rem 3rem 3rem;
}

.carousel-inner, .carousel-item {
height: 100%;
}

.carousel-item.active {
display: flex !important;
align-items: center;
justify-content: center;
}

.carousel-item img {
max-width: 100%;
max-height: 100%;
object-fit: cover;
}

.carousel-control-next {
right: 0 !important;
width: 3rem !important;
}

.carousel-control-prev {
left: 0 !important;
width: 3rem !important;
}


.item_image_spinner {
display: flex;
z-index: 1;
position: absolute;
width: 100%;
height: 100%;
align-items: center;
color: #16728f;
justify-content: center;
}

.items .item_image_spinner {
height: 12rem;
}

.item_image_spinner + .carousel {
opacity: 0;
}


#info ul {
margin: 0 0 20px 0;
padding: 0;
list-style: none;
}

#info ul > li {
min-height: 10px;
}




@media (prefers-color-scheme: light) {
    body {
    background-color: #FFF !important;
    }

    #app {
    background-color: #FFF;
    }

    .nav-link {
    color: #777 !important;
    }

    .nav-link:hover, .nav-link.active {
    color: #333 !important;
    }


    .dropdown-item {
    color: #777 !important;
    }

    .dropdown-item.active {
    background-color: #FFF !important;
    }

    .dropdown-item.active:hover {
    background-color: #e9ecef !important;
    }


    @media (min-width: 480px) {
        .navbar {
        border-top: 1rem #FFF solid;
        }
    }


    .form-control::placeholder {
    color: #CCC !important;
    }


    .carousel-indicators {
    filter: brightness(0);
    }

    .carousel-control-next, .carousel-control-prev {
    filter: brightness(0);
    }


    .items .item_image_spinner {
    background-color: #FFF;
    }


    .item_left > a, .item_right > a {
    color: #333;
    font-size: 0.8rem;
    }

    .item_left > a:hover, .item_right > a:hover {
    color: #777;
    text-decoration: none;
    }


    #login > button {
    color: #CCC;
    }

    #info a {
    color: #333;
    }


    .modal-backdrop {
    background-color: #FFF !important;
    opacity: 0.8 !important;
    }
}



@media (prefers-color-scheme: dark) {
    body {
    background-color: #333 !important;
    color: #FFF !important;
    }

    #app {
    background-color: #333;
    }

    #background {
    filter: invert();
    }

    .modal-content {
    background-color: #333 !important;
    }
    
    .close {
    color: #FFF !important;
    text-shadow: none !important;
    }

    input, textarea {
    background-color: #666 !important;
    border: 0 !important;
    color: #FFF !important;
    }

    .modal-header, .modal-footer {
    border-color: #777 !important;
    }

    .card {
    border-color: #777 !important;
    }

    .modal .card {
    background-color: #555 !important;
    }

    .navbar-light .navbar-toggler {
    filter: invert();
    }

    .navbar {
    background-color: #444 !important;
    border-bottom: 1rem #333 solid !important;
    }

    @media (min-width: 480px) {
        .navbar {
        border-top: 1rem #333 solid;
        }
    }


    .nav-link {
    color: #CCC !important;
    }

    .nav-link:hover, .nav-link.active {
    color: #FFF !important;
    }


    .dropdown-menu {
    background-color: #555 !important;
    }

    .dropdown-item {
    color: #FFF !important;
    }

    .dropdown-item.active {
    background-color: #555 !important;
    }

    .dropdown-item:hover, .dropdown-item.active:hover {
    background-color: #666 !important;
    }


    .form-control::placeholder {
    color: #AAA !important;
    }


    .navbar-toggler {
    border-color: #333 !important;
    }


    .item_facts {
    color: #FFF;
    }


    .items .item_image_spinner {
    background-color: #444;
    }


    .item_left > a, .item_right > a {
    color: #CCC;
    font-size: 0.8rem;
    }

    .item_left > a:hover, .item_right > a:hover {
    color: #FFF;
    text-decoration: none;
    }


    #login > button {
    color: #444;
    }


    #info a {
    color: #FFF;
    }


    .modal-backdrop {
    background-color: #333 !important;
    opacity: 0.8 !important;
    }
}



.with_token #curriculum_vitae_frame {
padding: 1rem;
border: 1px #CCC solid;
}